/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as firebase from "firebase/app";
import {environment} from "./src/environments/environment";

if (typeof window !== 'undefined') {
  firebase.initializeApp(environment.firebase);
}
