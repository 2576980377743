// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compra-js": () => import("./../src/pages/compra.js" /* webpackChunkName: "component---src-pages-compra-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mas-informacion-js": () => import("./../src/pages/mas-informacion.js" /* webpackChunkName: "component---src-pages-mas-informacion-js" */),
  "component---src-pages-pagar-js": () => import("./../src/pages/pagar.js" /* webpackChunkName: "component---src-pages-pagar-js" */)
}

