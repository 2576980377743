export const environment = {
  firebase: {
    apiKey: "AIzaSyDvUWSIOdHncsSjZ71mqh612wzRNEi_eeg",
    authDomain: "eldiario-tools.firebaseapp.com",
    databaseURL: "https://eldiario-tools.firebaseio.com",
    projectId: "eldiario-tools",
    storageBucket: "eldiario-tools.appspot.com",
    messagingSenderId: "1005698058653",
    appId: "1:1005698058653:web:a6816047de369ca0454774"
  },
  brainHubApiUrl: "https://usersapi.eldiario.es",
  stripeApiKey: "pk_live_qU3vX6MS6V3domLab9otiTYN"
}
